import { Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactComponent as UnPaidIcon } from 'app/assets/ic_Spam.svg';
import { ReactComponent as PaidIcon } from 'app/assets/paidIcon.svg';
import { IconComponents } from 'app/components/Icons';
import Avatar from 'app/components/Icons/Avatar';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

interface StudentComponentProps {
  student: any;
}

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  .student-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #44444f;
  }

  .student-id {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #92929d;
    display: flex;
    justify-content: flex-start;
  }
`;

export const StudentComponent = (props: StudentComponentProps) => {
  return (
    <ComponentStyled key={props.student?.id}>
      <div className="d-flex flex-row gap-2 ms-2" style={{ width: '100%' }}>
        <Avatar className="student-avatar mt-2" name={props.student?.avatarImageName} width="48px" height="48px" />
        <div>
          <Paragraph className="student-name m-0 p-0" ellipsis={{ tooltip: true }} style={{ width: '100%' }}>
            {_.capitalize(props?.student?.name)} {_.capitalize(props?.student?.lastName)}
          </Paragraph>

          <div className="student-id">{props.student.studentId}</div>
        </div>
        <div className="d-flex flex-row mt-2">
          {props.student.freeTrial && (
            <Tooltip title="Trial Student">
              <PaidIcon style={{ marginTop: '3px' }} />
            </Tooltip>
          )}
          {props.student.isSen && (
            <Tooltip title="Special Needs">
              <UnPaidIcon width={24.3} height={24.3} />
            </Tooltip>
          )}
          {props.student?.cancellationPendingDate && (
            <Tooltip
              title={`${
                moment(props.student?.cancellationPendingDate).isAfter(moment()) ? 'Cancelling on' : 'Cancelled on'
              } ${moment(props.student?.cancellationPendingDate).format('DD MMM YYYY')}`}
            >
              <IconComponents.StudentClassCancelIconComponent
                style={{ marginTop: '3px' }}
                svg={{ width: 18, height: 18 }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </ComponentStyled>
  );
};
