import { humanize } from '@alduino/humanizer/string';
import { Popconfirm, Typography } from 'antd';
import Avatar from 'app/components/Icons/Avatar';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl, TextArea } from 'app/components/v1/Input';
import { EMPTY_DATA_MARK } from 'app/utils/utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import DateText from '../DateText/DateText';
import { IconComponents } from '../Icons';
import { FileUploader } from '../v1/FileUploader';

const NoteStyled = styled.div`
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 25px 15px;
  .note-content-container {
    margin-top: 25px;
    .note-content {
      font-family: var(--st-font-family-primary);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #696974;
      height: fit-content;
    }
  }

  .agent-info-container {
    margin-left: 10px;
    .name {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.1px;

      color: #44444f;
    }
    .date {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.1px;

      color: #92929d;
    }
  }

  .edit-btn {
    background: transparent;
    padding: 0px;
    margin: 0px;
    height: fit-content;
    margin-left: 15px;
  }
  .delete-btn {
    background: transparent;
    padding: 0px;
    height: fit-content;
    margin: 0px;
  }
`;

export function Note(props: NoteProps) {
  const [status, setStatus] = useState<'editing' | 'idle'>('idle');
  const form = useFormik<{ content?: string; attachments?: any }>({
    initialValues: {
      content: props.content,
      attachments: props.attachments
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      content: yup.string().nullable().trim().required('Please add some text')
    }),
    onSubmit: async (values) => {
      await props.onSaved(values.content!, values.attachments);
      setStatus('idle');
    }
  });
  return (
    <NoteStyled>
      <div className="d-flex flex-row">
        <div className="d-flex flex-row col">
          <Avatar name={props.avatar} width={32} height={32} />
          <div className="agent-info-container d-flex flex-column">
            <span className="name">{props.systemGenerated ? 'System' : props.name || EMPTY_DATA_MARK}</span>
            <span className="date">
              {' '}
              <DateText date={props.datetime} format="fullDate" />
            </span>
            <span className="date">
              {props.source && props?.source?.length > 0
                ? `Source: ${
                    props?.source == 'student_detail'
                      ? 'Student profile'
                      : props?.source == 'parent_detail'
                        ? 'Parent profile'
                        : props?.source == 'sales'
                          ? 'Sales page'
                          : props?.source == 'parent_payment'
                            ? 'Parent payment page'
                            : props?.source == 'register'
                              ? 'Register page'
                              : props?.source == 'teacher_detail'
                                ? 'Teacher page'
                                : humanize(props.source)
                  }`
                : ''}
            </span>
          </div>
        </div>

        {!props.systemGenerated && (
          <div className="d-flex flex-row">
            <Popconfirm
              disabled={props.disabled}
              title="Are you sure ?"
              onConfirm={async () => {
                if (status === 'idle') {
                  await props.onDelete();
                }
                await form.resetForm();
                setStatus('idle');
              }}
            >
              <Button buttonType="text" disabled={props.disabled}>
                {status === 'editing' ? 'Cancel' : <IconComponents.DeleteIconComponent />}
              </Button>
            </Popconfirm>
            <Button
              disabled={props.disabled}
              buttonType="text"
              onClick={async (e) => {
                if (status === 'editing') {
                  await form.submitForm();
                } else {
                  setStatus('editing');
                }
              }}
            >
              {status === 'editing' ? 'Save' : <IconComponents.EditIconComponent />}
            </Button>
          </div>
        )}
      </div>
      <div className={`note-content-container d-flex flex-column w-100 ${status === 'editing' ? 'gap-2' : ''}`}>
        {status === 'editing' ? (
          <FormControl>
            <TextArea
              className="note-content"
              value={form.values.content}
              disabled={status !== 'editing'}
              onChange={(value) => {
                form.setFieldValue('content', value);
              }}
            />
            {form.errors.content && <Errors errors={{ required: [form.errors.content] }} />}
          </FormControl>
        ) : (
          <Typography.Paragraph
            className="note-content"
            ellipsis={{ expandable: true, rows: 2, symbol: 'Read more' }}
            style={{ whiteSpace: 'pre-line' }}
          >
            {props.content}
          </Typography.Paragraph>
        )}
        {props.attachments?.length > 0 || status === 'editing' ? (
          <FileUploader
            ref={props?.notesAttachmentsUploaderRef}
            title="Add files"
            path="note/attachments"
            styles={{ height: '30px' }}
            listClassName="notes-files"
            files={form.values.attachments || []}
            multiple
            allowDocuments
            allowImages
            disabled={status !== 'editing'}
            onChanged={(payload) => {
              form.setFieldValue('attachments', payload?.files || []);
            }}
          ></FileUploader>
        ) : (
          <></>
        )}
      </div>
    </NoteStyled>
  );
}

export type NoteProps = {
  avatar: string;
  name: string;
  datetime: string;
  content: string;
  disabled?: boolean;
  onSaved: (content: string, attachments: any) => Promise<void>;
  onDelete: () => void;
  source: string;
  attachments: any;
  notesAttachmentsUploaderRef: React.MutableRefObject<any>;
  systemGenerated: boolean;
};
