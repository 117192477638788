import { PayloadAction, configureStore, createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { adminApi } from 'app/services/admin';
import _ from 'lodash';
import moment from 'moment';
import api from './services';
import { Admin, EntityType, Parent, Student, Teacher, UserType } from './services/types';
import { EventPayload } from './services/web-socket/ws-socket.service';

export const LESSON_SLICE_KEY = 'pages/lesson';

export type StatusState = {
  isLoading: boolean;
};

export type LessonState = {
  registerStudents: RegisterStudent[];
  isSaved?: boolean;
};

export type RegisterStudent = {
  registerStudentId: string;
  selected?: boolean;
  studentId: string;
  attendance?: string;
  understanding?: string;
  engagement?: string;
  favorite?: boolean;
  issueDescription?: string;
  issueReasons?: { label: string; value: string }[];
  issueSendToParent?: boolean;
  issueStatus?: string;
  lastUpdate: string;
};

const syncRegisterStudent = createAction<{ registerStudent: RegisterStudent }, 'syncRegisterStudent'>(
  'syncRegisterStudent'
);

const registerStudentsSaved = createAction<{ isSaved?: boolean }>('registerStudentsSaved');

const lessonSliceInitialState = {
  registerStudents: [] as any,
  isSaved: false
};
export const lessonSlice = createSlice({
  name: LESSON_SLICE_KEY,
  initialState: lessonSliceInitialState,
  reducers: {
    [registerStudentsSaved.type]: (state, action: PayloadAction<{ isSaved: boolean }>) => {
      state.isSaved = action.payload.isSaved;
      if (action.payload.isSaved) {
        window.localStorage.removeItem('registeredStudents');
        state.registerStudents = [];
      }
    },
    [syncRegisterStudent.type]: (state, action: PayloadAction<{ registerStudent: RegisterStudent }>) => {
      let registerStudent = state.registerStudents.find(
        (_registerStudent: any) =>
          _.toString(_registerStudent.registerStudentId) ===
          _.toString(action.payload.registerStudent.registerStudentId)
      );
      if (!registerStudent) {
        registerStudent = action.payload.registerStudent;
        state.registerStudents.push(action.payload.registerStudent);
      } else {
        let shouldUpdate = false;
        if (action.payload.registerStudent.lastUpdate) {
          shouldUpdate = moment(action.payload.registerStudent.lastUpdate).isSameOrAfter(registerStudent.lastUpdate);
        }
        if (shouldUpdate) {
          registerStudent.studentId = action.payload.registerStudent.studentId;
          if (!_.isUndefined(action.payload.registerStudent.attendance)) {
            registerStudent.attendance = action.payload.registerStudent.attendance;
          }
          if (!_.isUndefined(action.payload.registerStudent.understanding)) {
            registerStudent.understanding = action.payload.registerStudent.understanding;
          }
          if (!_.isUndefined(action.payload.registerStudent.engagement)) {
            registerStudent.engagement = action.payload.registerStudent.engagement;
          }
          if (!_.isUndefined(action.payload.registerStudent.favorite)) {
            registerStudent.favorite = action.payload.registerStudent.favorite;
          }
          if (!_.isUndefined(action.payload.registerStudent.issueReasons)) {
            registerStudent.issueReasons = action.payload.registerStudent.issueReasons;
          }
          if (!_.isUndefined(action.payload.registerStudent.issueDescription)) {
            registerStudent.issueDescription = action.payload.registerStudent.issueDescription;
          }
          if (!_.isUndefined(action.payload.registerStudent.issueSendToParent)) {
            registerStudent.issueSendToParent = action.payload.registerStudent.issueSendToParent;
          }
          if (!_.isUndefined(action.payload.registerStudent.issueStatus)) {
            registerStudent.issueStatus = action.payload.registerStudent.issueStatus;
          }
          if (!_.isUndefined(action.payload.registerStudent.selected)) {
            registerStudent.selected = action.payload.registerStudent.selected;
          }
        }
      }
      registerStudent.lastUpdate = moment().toString();
    },
    reset: () => {
      return _.cloneDeep(lessonSliceInitialState);
    }
  }
});

export const actions = lessonSlice.actions;

export const selector = (state: AppStore) => state && state[LESSON_SLICE_KEY];

export const isSavedSelect = (state: AppStore) => state && state[LESSON_SLICE_KEY].isSaved;

// -------------------------------------------------------

export const layoutSlice = createSlice({
  initialState: {
    isLoading: false
  },
  name: 'layoutState',
  reducers: {
    loadingEnabled: (state) => {
      state.isLoading = true;
      return state;
    },
    loadingDisabled: (state) => {
      state.isLoading = false;
      return state;
    }
  }
});

export const statusSlice = createSlice({
  initialState: {
    isLoading: false
  } as StatusState,
  name: 'statusSlice',
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
      return state;
    }
  }
});

const userStoreInitialState = {
  roles: [],
  user: null,
  familyLinks: null
};
export const userStore = createSlice({
  initialState: userStoreInitialState,
  name: 'user',
  reducers: {
    userLoaded: (state, action) => {
      state = _.assign(state, action.payload);
      return state;
    },
    familyLinksUpdated: (state, action) => {
      state.familyLinks = {
        ...(state.familyLinks ?? {}),
        ...action.payload
      };
      return state;
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
    },
    reset: () => {
      return _.cloneDeep(userStoreInitialState);
    }
  }
});

// -------------------------------------------------------
const notificationsSliceInitialState: NotificationsSliceState = {
  notification: null
};
export const notificationsSlice = createSlice({
  initialState: notificationsSliceInitialState,
  name: 'notifications',
  reducers: {
    newNotification: (state, action) => {
      return {
        ...state,
        notification: action.payload
      };
    },
    reset: () => {
      return _.cloneDeep(notificationsSliceInitialState);
    }
  }
});

export type NotificationsSliceState = {
  notification: Notification;
};

export type Notification = EventPayload | any;

// -------------------------------------------------------

export type ClassesState = {
  marks?: any;
  deadline: string | null;
  questions?: any[];
  solutions?: any[];
  lessonFiles?: any[];
  disabled?: boolean;
};
const classesSliceInitialValue: ClassesState = {
  marks: null,
  deadline: null,
  questions: [],
  solutions: [],
  lessonFiles: [],
  disabled: false
};
export const classesSlice = createSlice({
  name: 'classes',
  initialState: classesSliceInitialValue,
  reducers: {
    updateDisabled: (state, action) => {
      state.disabled = action.payload.disabled;
    },
    updateMarks: (state, action) => {
      state.marks = action.payload.marks;
    },
    updateDeadline: (state, action) => {
      state.deadline = action.payload.deadline;
    },
    updateQuestions: (state, action) => {
      state.questions = action.payload.questions;
    },
    updateSolutions: (state, action) => {
      return {
        ...state,
        solutions: action.payload.solutions
      };
    },
    updateLessonFiles: (state, action) => {
      state.lessonFiles = action.payload.lessonFiles;
    },
    reset: () => {
      return _.cloneDeep(classesSliceInitialValue);
    }
  }
});

export const { updateDeadline, updateMarks, updateQuestions, updateLessonFiles, updateSolutions, updateDisabled } =
  classesSlice.actions;

export const selectors = {
  marks: (state: any) => state.classes.marks,
  deadline: (state: any) => state.classes.deadline,
  questions: (state: any) => state.classes.questions,
  lessonFiles: (state: any) => state.classes.lessonFiles,
  solutions: (state: any) => state.classes.solutions,
  disabled: (state: any) => state.classes.disabled
};

// -------------------------------------------------------

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [classesSlice.name]: classesSlice.reducer,
    [userStore.name]: userStore.reducer,
    [lessonSlice.name]: lessonSlice.reducer,
    [statusSlice.name]: statusSlice.reducer,
    [layoutSlice.name]: layoutSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware, adminApi.middleware])
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppStore = {
  user: UserStore;
  [notificationsSlice.name]?: NotificationsSliceState;
  [classesSlice.name]?: ClassesState;
  [lessonSlice.name]: LessonState;
  [statusSlice.name]: StatusState;
  [layoutSlice.name]: LayoutState;
};

export type UserStore = {
  familyLinks?: {
    createdAt: string;
    updatedAt: string;
    parents: (number | string)[];
    students: (number | string)[];
    id: number | string;
  };
  id: number | string;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  source?: any;
  additionalPermission?: any;
  createdAt: string;
  updatedAt: string;
  admin?: Admin;
  user_types: EntityType[];
  student?: Student;
  parent?: Parent;
  teacher?: Teacher;
  entity: Teacher | Student | Parent | Admin | any;
  userType: UserType;
  roles: any[];
};

export type LayoutState = {
  isLoading: boolean;
};

export const MAIN_SELECTORS = {
  user: createSelector(
    (state: AppStore) => state,
    (state) => state.user
  ),
  notifications: createSelector(
    (state: AppStore) => state,
    (state) => state[notificationsSlice.name]
  ),
  layout: createSelector(
    (state: AppStore) => state,
    (state) => state[layoutSlice.name]
  )
};
