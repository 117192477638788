import qs from 'qs';
import api from '.';
import { ApiResponse, ApiResponseData, MeetingAccount, RequestQuery } from './types';

export const meetingAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findManyMeetingAccount: builder.query<ApiResponse<ApiResponseData<MeetingAccount>[]>, RequestQuery>({
      providesTags: ['MeetingAccount'],
      query: (payload) => ({
        url: `/api/meeting-accounts?${qs.stringify(payload, { encodeValuesOnly: true })}`,
        method: 'GET'
      })
    }),
    findOneMeetingAccount: builder.query<ApiResponse<ApiResponseData<MeetingAccount>>, { id: string; query?: RequestQuery }>({
      providesTags: ['MeetingAccount'],
      query: (payload) => {
        const query = qs.stringify(payload.query, { encodeValuesOnly: true });
        return {
          url: `/api/meeting-accounts/${payload.id}?${query}`,
          method: 'GET'
        };
      }
    }),
    createOneMeetingAccount: builder.mutation<void, { body: Partial<MeetingAccount> }>({
      invalidatesTags: ['MeetingAccount'],
      query: (payload) => ({
        url: `/api/meeting-accounts`,
        method: 'POST',
        body: { data: payload.body }
      })
    }),
    updateOneMeetingAccount: builder.mutation<void, { id: string; body: Partial<MeetingAccount> }>({
      invalidatesTags: ['MeetingAccount'],
      query: (payload) => ({
        url: `/api/meeting-accounts/${payload.id}`,
        method: 'PUT',
        body: { data: payload.body }
      })
    }),
    deleteOneMeetingAccount: builder.mutation<void, { id: string }>({
      invalidatesTags: (result, error, { id }) => [{ type: 'MeetingAccount', id }],
      query: (payload) => ({
        url: `/api/meeting-accounts/${payload.id}`,
        method: 'DELETE'
      })
    })
    
  })
});
