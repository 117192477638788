import { animated, useSpring } from '@react-spring/web';
import { message } from 'antd';
import { idConfigApi } from 'app/services/id-config';
import { useDeleteFileMutation, useUploadFileMutation } from 'app/services/upload';
import { download, getFileUrl } from 'app/utils/utils';
import _ from 'lodash';
import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { IconComponents } from '../Icons';
import Typography from '../Typography/Typography';
import { Errors, FormControlError } from './Input';

const FileUploaderStyled = styled.div`
  .st-v1-files {
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;

    &.notes-files {
      display: flex;
      flex-direction: row !important;
      overflow-x: scroll;
      overflow-y: hidden;

      & > .st-v1-file {
        height: 30px;
        padding-inline: 5px;
        display: flex !important;
        flex-direction: row !important;

        > span {
          display: flex !important;
          flex-direction: row !important;
          gap: 7px;
          padding-right: 45px;
        }
        & > .st-v1-icon {
          top: 3px;
        }
      }
      @media (min-width: 992px) {
        flex-direction: row !important;
        flex-wrap: nowrap !important;
      }
    }

    & > .st-v1-file {
      position: relative;
      background: #ffffff;
      border: 1px dashed currentColor;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      width: 190px;
      height: 130px;
      cursor: pointer;

      & > .st-v1-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        z-index: 1;
      }
    }

    & > li > label.st-v1-file-input {
      position: relative;
      background: #ffffff;
      border: 1px dashed currentColor;
      border-radius: 10px;
      width: 190px;
      height: 130px;

      &.is-invalid {
        color: #fc5a5a;
      }
    }

    .state-icon {
      position: absolute;
    }

    .file-extension {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .ext {
        position: absolute;
        font-size: 5px;
        margin-top: 5px;
      }
    }
  }

  label.st-v1-file-input {
    width: 190px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #ffffff;
    border: 1px dashed currentColor;
    border-radius: 10px;
    height: 58px;
    color: #4c54a0;
    font-weight: 600;
    font-size: 12px;
    text-align: center;

    .file-name {
      color: currentColor;
    }
    .st-v1-icon {
      position: absolute;
      right: 10px;
      display: inline-block;
      width: 15px;
      height: 15px;
      z-index: 100;
    }

    &.is-invalid {
      color: #fc5a5a;
    }

    &:has(input[disabled]) {
      color: #b5b5be;
    }
  }
  .st-v1-delete-button {
    background: transparent;
    outline: none;
    border: 0;
    color: #92929d;
    padding: 0px;
    margin: 0px;
  }
`;

export const FileUploader = forwardRef((props: React.PropsWithChildren<FileUploaderProps>, ref: any) => {
  const fileInputRef = useRef<any>();
  const { data: adminSettingData } = idConfigApi.endpoints.getAdminSetting.useQuery();
  const maxFileSize = adminSettingData?.data.attributes.maxFileSize || 250;
  const [errors, setErrors] = useState<FormControlError | undefined>(props.errors);

  const [files, setFiles] = useState<File[]>(props?.files || []);

  const acceptedCriteria = `application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation`;

  const inputFileId = useId();

  const [uploadingStatus, setUploadingStatus] = useState<any>({});

  useImperativeHandle(
    ref,
    () => ({
      /**
       * Compare between the files and the old files and delete the additional
       * files or add the new ones.
       * @param files the current files
       * @param previousFiles the old files
       * @returns
       */
      save: async (_files: File[], previousFiles: File[]) => {
        return saveChanges(_files, previousFiles);
      },
      /**
       * cancel all the changes and reset the files
       * @param files
       * @returns
       */
      cancel: async (_files: File[]) => {
        return cancelChanges(_files);
      }
    }),
    [files, props.files, ref]
  );

  const [uploadFile, { isLoading: uploading, isError: uploadFailed, status: uploadingState }] = useUploadFileMutation();

  const [deleteFile, { isLoading: deleting, isError: deleteFailed, status: deletingStatus }] = useDeleteFileMutation();

  useEffect(() => {
    props.setFileIsChanging && props.setFileIsChanging(uploading || deleting);
  }, [uploading, deleting]);

  useEffect(() => {
    const mappedFiles =
      props.files?.map((file) => {
        file.key = file.key ?? _.uniqueId();
        return file;
      }) || [];
    setFiles(mappedFiles);
  }, [props.files]);

  const accept = useMemo(() => {
    if (props.accept) {
      return props.accept;
    } else if (props.allowAll) {
      return '';
    } else if (props.allowImages || props.allowDocuments) {
      const accept = [];
      if (props.allowImages) {
        accept.push('image/*,');
      }
      if (props.allowDocuments) {
        accept.push(acceptedCriteria);
      }
      return accept.join('');
    } else {
      return `image/*,${acceptedCriteria},${props.additionalFormats}`;
    }
  }, [props.accept, props.allowImages, props.allowDocuments]);

  const saveChanges = useCallback(
    async (_currentFiles: File[], _previousFiles: File[]) => {
      let currentFiles: File[] = _.cloneDeep(_currentFiles);
      // remove the deleted files from the state and of the server if it was uploaded
      const deletedFiles: File[] = _.differenceBy(_previousFiles, currentFiles, (f) => f.id?.toString()!);
      deletedFiles.forEach((e) => {
        uploadingStatus[e.key?.toString()!] = 'uploading';
      });
      setUploadingStatus({ ...uploadingStatus });
      for (const deletedFile of deletedFiles) {
        if (deletedFile.id) {
          await deleteFile(deletedFile.id!);
        }
        uploadingStatus[deletedFile.key?.toString()!] = 'uploaded';
        setUploadingStatus({ ...uploadingStatus });
      }
      currentFiles = currentFiles?.filter((f) => !deletedFiles.find((df) => df.id?.toString() === f.id?.toString()));
      // upload the new files and set the ids
      const newFiles: File[] = currentFiles?.filter((f) => !f.id && !!f.file) || [];
      setFiles(currentFiles);
      newFiles.forEach((e) => {
        uploadingStatus[e.key?.toString()!] = 'uploading';
      });
      setUploadingStatus({ ...uploadingStatus });
      for (const newFile of newFiles) {
        const formData = new FormData();
        formData.append('files', newFile.file);
        props.path && formData.append('path', props.path);
        const response: any = await uploadFile(formData);
        newFile.id = response.data[0].id;
        newFile.url = response.data[0].url;
        newFile.type = response.data[0].mime;
        setFiles(currentFiles);
        uploadingStatus[newFile.key?.toString()!] = 'uploaded';
        setUploadingStatus({ ...uploadingStatus });
      }
      newFiles.forEach((e) => {
        setTimeout(() => {
          delete uploadingStatus[e.key?.toString()!];
          setUploadingStatus({ ...uploadingStatus });
        }, 1000);
      });
      deletedFiles.forEach((e) => {
        delete uploadingStatus[e.key?.toString()!];
        setUploadingStatus({ ...uploadingStatus });
      });
      return {
        files: currentFiles
      };
    },
    [props.files, files, uploadingStatus]
  );

  const cancelChanges = useCallback(
    async (files: File[]) => {
      setFiles(_.cloneDeep(files));
    },
    [files, props.files]
  );

  const removeFile = async (index: number) => {
    try {
      const filteredFiles = files?.filter((file) => file.key?.toString() !== files[index].key?.toString());
      props.onChanged &&
        props.onChanged({
          files: _.cloneDeep(filteredFiles)
        });
      if (props.autoSave) {
        await saveChanges(filteredFiles, files);
      }
      // reset the input file value if you need to reupload the deleted file
      if (!filteredFiles.length && fileInputRef?.current?.value) {
        fileInputRef.current.value = '';
      }
    } catch (e) {
      message.error("can't delete the file");
    }
  };
  const validateFile = async (f: any): Promise<FormControlError | null> => {
    const allowedTypes: string[] = [];
    const accepted = props.additionalFormats ? `${acceptedCriteria},${props.additionalFormats}` : acceptedCriteria;
    let msg = 'Please upload images or documents only';
    let isImage,
      isDoc = false;

    if (!props.allowAll && !props.allowDocuments && !props.allowImages) {
      const isImage = String(f.type.toLowerCase()).startsWith('image/');
      const isDoc = accepted
        .split(',')
        .map((e) => e.trim())
        .includes(f.type.toLowerCase());
      if (!isImage && !isDoc)
        return {
          invalidType: ['File type not supported']
        };
    }
    if (!props.allowDocuments && !props.allowImages) return null;
    if (props.allowDocuments && !props.allowImages) msg = 'Please upload documents only';
    else if (!props.allowDocuments && props.allowImages) msg = 'Please upload images only';
    else if (props.allowDocuments && props.allowImages) msg = 'Please upload documents or images';

    if (props.allowDocuments) {
      allowedTypes.push(...accepted.split(',').map((e) => e.trim()));
    }

    if (props.allowImages) {
      isImage = String(f.type.toLowerCase()).startsWith('image/');
    }

    if (props.allowDocuments) {
      isDoc = allowedTypes.includes(f.type.toLowerCase());
    }

    if (!isDoc && !isImage) {
      return {
        invalidType: [msg]
      };
    }
    return null;
  };
  const addFiles = async (newFiles: FileList) => {
    if (!newFiles || !newFiles?.length) {
      return;
    }
    try {
      let savedFiles: File[] = _.cloneDeep(files);

      const readFileAsDataURL = (file: any): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.onerror = reject;
          fileReader.readAsDataURL(file);
        });
      };

      const filePromises = Array.from(newFiles).map(async (f: any) => {
        if (f.size) {
          const validationResult = await validateFile(f);

          if (f.size > maxFileSize * 1000 * 1000) {
            setErrors({ invalidType: [`File size exceeds ${maxFileSize} MB limit.`] });
            return null;
          }
          if (validationResult) {
            setErrors(validationResult);
            return null;
          } else {
            setErrors(undefined);
          }

          const data = await readFileAsDataURL(f);

          if (typeof data === 'string') {
            return {
              name: f.name,
              key: _.uniqueId(),
              url: data,
              type: f.type,
              file: f
            };
          }
        }
      });

      const processedPromises = await Promise.all(filePromises);

      if (!processedPromises?.[0]) {
        setFiles([...savedFiles]);
        return null;
      }
      const processedFiles = processedPromises?.filter(
        (file): file is Exclude<typeof file, null | undefined> => file !== null && file !== undefined
      );

      if (props.autoSave && processedFiles.length > 0) {
        savedFiles = (await saveChanges([...savedFiles, ...processedFiles], savedFiles)).files;
        props.onChanged &&
          props.onChanged({
            files: _.cloneDeep([...savedFiles])
          });
      } else {
        setFiles([...savedFiles, ...processedFiles]);
        props.onChanged &&
          props.onChanged({
            files: _.cloneDeep([...savedFiles, ...processedFiles])
          });
      }
    } catch (e) {
      message.error("can't delete the file");
    }
  };

  const onDropFiles = useCallback(
    async (newFiles: FileList) => {
      await addFiles(newFiles);
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFiles as any,
    disabled: !!props.disabled,
    noClick: true
  });

  return (
    <FileUploaderStyled style={props.styles} {...getRootProps()} className={props.className}>
      {props.multiple && (
        <ul className={`st-v1-files d-flex flex-column flex-lg-row flex-lg-wrap ${props.listClassName}`}>
          {files?.map((file, i) => {
            return (
              <li
                className={`st-v1-file d-flex flex-column align-items-center justify-content-center`}
                key={file.key}
                title={file.name}
                onClick={(e) => {
                  e.preventDefault();
                  if (uploadingStatus[file?.key?.toString()!] != 'uploading') {
                    download(getFileUrl(file), file.name);
                  }
                }}
              >
                <span className="d-flex flex-column align-items-center">
                  <span className="file-extension">
                    <IconComponents.FileIconComponent />
                    <span className="ext">{file.name.split('.').slice(-1)}</span>
                  </span>
                  <span className=" text-center file-name" style={{ width: '100px', whiteSpace: 'nowrap' }}>
                    {file.name?.length > 12
                      ? `${file.name
                          .split('.')[0]
                          .substring(
                            0,
                            12 - file.name.split('.').slice(-1).length > 0
                              ? 12 - file.name.split('.').slice(-1).length
                              : 6
                          )}...${file.name.split('.').slice(-1)}`
                      : file.name}
                  </span>
                </span>
                <div className="st-v1-icon">
                  {uploadingStatus[file?.key?.toString()!] ? (
                    <StateIcon state={uploadingStatus[file?.key?.toString()!]} />
                  ) : !props.disabled ? (
                    <button
                      disabled={props.disabled || deleting || !!uploadingStatus[file.key?.toString()!]}
                      className="st-v1-delete-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeFile(i);
                      }}
                    >
                      <IconComponents.DeleteIconComponent svg={{ width: '15px', height: '15px' }} />
                    </button>
                  ) : null}
                </div>
              </li>
            );
          })}
          {!props.disabled && (
            <li>
              <label
                htmlFor={inputFileId}
                style={props.styles}
                className={`st-v1-file-input d-flex flex-column align-items-center justify-content-center ${
                  errors && 'is-invalid'
                }`}
              >
                <span className="state-icon">
                  <StateIcon state={uploadingStatus} />
                </span>
                {props.title && props.title !== '' ? (
                  <div className="d-flex flex-row gap-2 align-items-center">
                    <span className="file-name"> {props.title}</span>
                    <IconComponents.PlusIconComponent svg={{ width: '15px', height: '15px' }} />
                  </div>
                ) : (
                  <IconComponents.PlusIconComponent svg={{ width: '32px', height: '32px' }} />
                )}
                <input
                  ref={fileInputRef}
                  {...getInputProps()}
                  disabled={props.disabled || deleting || uploading}
                  style={{ display: 'none' }}
                  id={inputFileId}
                  type="file"
                  onChange={(e: any) => {
                    fileInputRef.current = e.target;
                    e.preventDefault();
                    addFiles(e.target.files!);
                    e.target.value = '';
                  }}
                  multiple
                  accept={accept}
                />
              </label>
            </li>
          )}
        </ul>
      )}

      {!props.multiple && (
        <label
          htmlFor={inputFileId}
          className={`st-v1-file-input ${errors && 'is-invalid'}`}
          style={props.styles}
          onClick={(e) => {
            if (!props.disabled) return;
            e.preventDefault();
            if (uploadingStatus[files?.[files?.length - 1]?.key?.toString()!] != 'uploading')
              download(getFileUrl(files?.[files?.length - 1]), files?.[files?.length - 1].name);
          }}
        >
          <>
            <input
              ref={fileInputRef}
              disabled={props.disabled || deleting || !!uploadingStatus?.[files?.[files?.length - 1]?.key?.toString()!]}
              style={{ display: 'none' }}
              id={inputFileId}
              type="file"
              onClick={(e) => {
                fileInputRef.current = e.target;
                if (!files?.[files?.length - 1]) return;
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                fileInputRef.current = e.target;
                addFiles(e.target.files!);
                e.target.value = '';
              }}
              accept={accept}
            />
            {files?.[files?.length - 1]?.name ? (
              <Typography type="link" link={getFileUrl(files?.[files?.length - 1])} className="file-name" width="100px">
                {files?.[files?.length - 1]?.name}
              </Typography>
            ) : props.title && props.title !== '' ? (
              <>
                <IconComponents.PlusIconComponent svg={{ width: '15px', height: '15px' }} />
                <span className="file-name"> {props.title}</span>
              </>
            ) : (
              <IconComponents.PlusIconComponent svg={{ width: '15px', height: '15px' }} />
            )}
          </>
          <div className="st-v1-icon">
            {uploadingStatus[files?.[files?.length - 1]?.key?.toString()!] ? (
              <StateIcon state={uploadingStatus[files?.[files?.length - 1]?.key?.toString()!]} />
            ) : files?.[files?.length - 1] && !props.disabled ? (
              <button
                disabled={props.disabled || deleting || !!uploadingStatus[files?.[files?.length - 1]?.key?.toString()!]}
                className="st-v1-delete-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeFile(files?.length - 1);
                }}
              >
                <IconComponents.DeleteIconComponent svg={{ width: '15px', height: '15px' }} />
              </button>
            ) : null}
          </div>
        </label>
      )}
      {errors && <Errors errors={errors} />}
    </FileUploaderStyled>
  );
});
// --------------------------------------------------------------------------------------------------------
const StateIconStyled = styled.div`
  position: relative;
  .inner-state-icon {
    position: absolute;
    width: 15px;
    height: 15px;
  }
`;
const AnimatedLoadingIconComponent = animated(IconComponents.LoadingIconComponent);
const AnimatedFileUploadedIconComponent = animated(IconComponents.FileUploadedIconComponent);
const StateIcon = (props: { state: string }) => {
  const loadingAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: props.state === 'uploading' ? 1 : 0 }
  });

  const fileUploadedAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: props.state === 'uploaded' ? 1 : 0 }
  });

  return (
    <StateIconStyled>
      <AnimatedLoadingIconComponent className="inner-state-icon" style={loadingAnimation} />
      <AnimatedFileUploadedIconComponent
        className="inner-state-icon"
        color="primary"
        style={fileUploadedAnimation}
        svg={{ width: '15px' }}
      />
    </StateIconStyled>
  );
};

export type FileUploaderProps = {
  files?: File[];
  path?: string;
  multiple?: boolean;
  title?: string | React.ReactNode;
  disabled?: boolean;
  className?: string;
  listClassName?: string;
  styles?: CSSProperties;
  uploadIcon?: React.ReactNode;
  autoSave?: boolean;
  allowImages?: boolean;
  allowDocuments?: boolean;
  allowAll?: boolean;
  accept?: string;
  onChanged?: (payload: FileUploaderOnChanged) => void;
  errors?: FormControlError;
  additionalFormats?: string;
  setFileIsChanging?: (x: boolean) => void;
};
export type FileUploaderRef = {
  /**
   * call to save the uploaded files and delete the files and get the ids
   */
  save: (files: File[], previousFiles: File[]) => Promise<FileUploaderOnChanged>;
  /**
   * cancel all the changes.
   */
  cancel: () => Promise<void>;
};
export type FileUploaderOnChanged = {
  files: File[];
};
export type File = {
  key: string;
  id?: string;
  name: string;
  url: string;
  file?: any;
  type: string;
};
