import _ from 'lodash';
import { useMemo } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { tableColumnns } from './handleCheckersRegister';

const ClassCheckersRegisterStyled = styled.div`
  .table-header {
    background: #f1f1f5;
    border-radius: 10px;
    padding: 15px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #92929d;
  }
`;

const StyledTableRow = styled.tr`
  cursor: pointer;
`;

interface ClassCheckersRegisterTableProps {
  setColumnHeader?: (header: string) => void;
  currentHeaderSort?: string;
  sortDirection?: any;
  handleSorting?: any;
  sortable?: any;
  data: any[];
}

export const ClassCheckersRegisterTable = (props: ClassCheckersRegisterTableProps) => {
  const sortedData = useMemo(() => {
    return _.orderBy(
      props.data,
      [(item) => `${item.student.lastname?.toLowerCase()} ${item.student.name?.toLowerCase()}`],
      ['asc']
    );
  }, [props.data]);

  const { headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns: tableColumnns(),
    data: sortedData
  });
  return (
    <table>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map((column) => (
              <th
                key={column.id}
                className="a-s-t-th"
                /*                 onClick={() => {
                  props.setColumnHeader(column.Header as string);
                  props.handleSorting();
                }} */
              >
                <div
                  className={`d-flex ${
                    column.Header == 'Student' ? 'ms-6 justify-content-start' : 'justify-content-center'
                  } align-items-center`}
                >
                  <div>{column.Header as string}</div>

                  {/*                     <div className="d-flex flex-column ms-2">
                      <UpArrow
                        className={
                          column.Header === props.currentHeaderSort &&
                          !props.sortDirection[`${column.Header}`.split(' ').join('').toLowerCase()]
                            ? 'up-arrow active-arrow'
                            : 'up-arrow'
                        }
                      />
                      <DownArrow
                        className={
                          column.Header === props.currentHeaderSort &&
                          props.sortDirection[`${column.Header}`.split(' ').join('').toLowerCase()]
                            ? 'down-arrow active-arrow'
                            : 'down-arrow'
                        }
                      />
                    </div> */}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <StyledTableRow className="my-2 c-pointer" {...row.getRowProps()} key={'row' + index}>
              {row.cells.map((cell, index) => {
                return (
                  <td
                    className="a-s-td"
                    style={cell.column.Header == 'Teacher' ? { minWidth: '210px' } : {}}
                    {...cell.getCellProps()}
                    key={'cell' + index}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </StyledTableRow>
          );
        })}
      </tbody>
    </table>
  );
};
