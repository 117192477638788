import api from '.';
import { GetAdminSettingResponse } from './types';

export const AccountIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdminSetting: builder.query<GetAdminSettingResponse, void>({
      query: () => ({
        url: `/api/admin-setting`,
        method: 'GET'
      })
    }),
    getNewAccountId: builder.query<any, string>({
      query: (accountType) => ({
        url: `/api/id-config/getNewId/${accountType}`,
        method: 'GET'
      })
    }),
    peekNewClassId: builder.query<string, void>({
      query: () => ({
        url: `api/id-config/peekNewId/class`,
        method: 'GET',
        responseHandler: 'text'
      })
    })
  })
});

export const idConfigApi = AccountIdApi;

export const { useGetNewAccountIdQuery, useLazyGetNewAccountIdQuery } = AccountIdApi;
