import { Student } from 'app/services/types';
import { getCountries } from 'app/utils/get-countries';

export function formatContacts(
  familyLinks: any,
  setDefaultParent: (val: number | undefined) => void,
  form: any,
  primaryParentId?: number,
  student?: Student,
  studentId?: number
) {
  const options: any[] = [];
  let index = 1;
  let defaultSet = false;
  if (student?.phone || student?.phone2) {
    options.push({
      label: `${student.name} (Student)`,
      value: index,
      phone: student?.phone,
      phone2: student?.phone2,
      code: getCountries().filter((country) => country.code == student.phoneCountryCode)?.[0]?.dial_code,
      code2: getCountries().filter((country) => country.code == student.phoneCountryCode2)?.[0]?.dial_code,
      contactId: studentId,
      isParent: false,
      name: `${student.name ? student.name : ''} ${student.lastName ? student.lastName : ''}`
    });
    index += 1;
  }
  if (familyLinks?.length > 0) {
    if (familyLinks?.[0]?.attributes?.parents?.data?.length > 0) {
      familyLinks?.[0]?.attributes?.parents?.data.map((parent: any) => {
        if (parent.attributes.phone || parent.attributes.phone2) {
          if (primaryParentId == parent.id && defaultSet == false) {
            form.setFieldValue('phone', index);
            setDefaultParent(index);
            defaultSet = true;
          }
          options.push({
            label: `${parent.attributes.name}  ${primaryParentId == parent.id ? '(Default Parent)' : ''}`,
            value: index,
            phone: parent.attributes.phone,
            phone2: parent.attributes.phone2,
            code: getCountries().filter((country) => country.code == parent.attributes.phoneCountryCode)?.[0]
              ?.dial_code,
            code2: getCountries().filter((country) => country.code == parent.attributes.phoneCountryCode2)?.[0]
              ?.dial_code,
            contactId: parent.id,
            isParent: true,
            name: `${parent.name ? parent.name : ''} ${parent.lastName ? parent.lastName : ''}`
          });
        }
        index += 1;
      });
    }
    if (familyLinks?.[0]?.attributes?.students?.data?.length > 0) {
      familyLinks?.[0]?.attributes?.students?.data.map((student: any) => {
        if ((student?.attributes?.phone || student?.attributes?.phone2) && studentId != student?.id) {
          options.push({
            label: `${student?.attributes?.name} ${student?.attributes?.lastName}`,
            value: index,
            phone: student?.attributes?.phone,
            phone2: student?.attributes?.phone2,
            code: getCountries().filter((country) => country.code == student?.attributes?.phoneCountryCode)?.[0]
              ?.dial_code,
            code2: getCountries().filter((country) => country.code == student?.attributes?.phoneCountryCode2)?.[0]
              ?.dial_code,
            contactId: student?.id,
            isParent: false,
            name: `${student?.attributes?.name} ${student?.attributes?.lastName}`
          });
        }
        index += 1;
      });
    }
  } else {
    return [];
  }
  return options;
}

export const handleCallSubmission = async (props: HandleCallSubmissionData) => {
  props.setDisableGroupBtn(true);
  const targetContact: any = props.formattedContacts.filter(
    (contact: any) => props?.form?.values?.phone == contact.value
  )?.[0];
  props.setTargetId(targetContact?.contactId);
  props.setRole(targetContact?.isParent ? 'parent' : 'student');

  if (props.editMode == false) {
    await props.addNote(
      props.form.values.note,
      props.form.values.reason,
      props.form.values.response,
      targetContact?.isParent ? 'parent' : 'student',
      targetContact?.contactId,
      undefined,
      targetContact?.isParent && props.studentId ? props.studentId : undefined
    );
  } else {
    if (targetContact?.isParent) {
      await props.updateNote(
        props.editingNoteId,
        props.form.values.note,
        props.form.values.response,
        props.form?.values.reason,
        targetContact?.isParent && props.studentId ? props.studentId : null,
        targetContact?.contactId,
        props.form?.values.timestamp
      );
    } else {
      await props.updateNote(
        props.editingNoteId,
        props?.form?.values.note,
        props?.form?.values.response,
        props?.form?.values.reason,
        targetContact?.contactId,
        null,
        props?.form?.values.timestamp
      );
    }
  }
  if (props.groupChanged) {
    await props
      .updateStudent({
        id: props.studentId!,
        profileData: {
          group: props.form.values.group
        }
      })
      .unwrap();
  } else if (props.refetchStudents) {
    await props.refetchStudents();
  }

  props.setShowCallModal(false);
  props.form.resetForm();
  props.setEditMode(false);
  props.setGroupChanged(false);
  props.setDisableGroupBtn(false);
};

type HandleCallSubmissionData = {
  formattedContacts: any;
  form: any;
  setTargetId: (x: any) => void;
  setRole: (x: string) => void;
  editMode: boolean;
  addNote: Function;
  studentId?: number;
  updateNote: Function;
  editingNoteId?: string;
  setShowCallModal: (x: boolean) => void;
  setEditMode: (x: boolean) => void;
  updateStudent: any;
  groupChanged: boolean;
  setGroupChanged: (x: boolean) => void;
  setDisableGroupBtn: (x: boolean) => void;
  refetchStudents: any;
};
