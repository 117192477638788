import { Select } from 'app/components/v1/Select';
import { useUpdateRegisterStudentMutation } from 'app/services/register-student';

interface AttendanceSelectInput {
  value: { label: string; value: string };
  id: string;
  registerId: number;
}

const attendanceOptions = [
  { label: <span style={{ color: '#3DD598' }}>Attended</span>, value: 'attended' },
  { label: <span style={{ color: '#FFC542' }}>Late</span>, value: 'late' },
  { label: <span style={{ color: '#FFC542' }}>Left early</span>, value: 'leftEarly' },
  { label: <span style={{ color: '#FFC542' }}>Late & left early</span>, value: 'leftEarly&Late' },
  { label: <span style={{ color: '#FC5A5A' }}>Absent</span>, value: 'absent' }
];

export const AttendanceSelectInput = (props: AttendanceSelectInput) => {
  const [updateTeacherAttendance, { isLoading }] = useUpdateRegisterStudentMutation();

  return (
    <Select
      disabled={isLoading}
      containerclass="d-flex flex-row justify-content-center mx-2 mt-2"
      value={
        props.value?.value
          ? attendanceOptions.find((ele) => ele.value?.toString() === props.value?.value?.toString())
          : null
      }
      placeholder="Teacher Attendance"
      allowClear
      dropdownStyle={{ color: 'green' }}
      style={{ width: '170px', minWidth: '170px' }}
      options={attendanceOptions}
      onChange={async (value: string | null) => {
        const body: any = {
          registerId: props.registerId,
          hasAttended: null,
          hasLeftEarly: null,
          isLate: null
        };

        if (value) {
          if (value === 'attended') {
            body.hasAttended = true;
            body.hasLeftEarly = false;
            body.isLate = false;
          } else if (value === 'late') {
            body.isLate = true;
            body.hasAttended = true;
            body.hasLeftEarly = false;
          } else if (value === 'leftEarly') {
            body.hasLeftEarly = true;
            body.hasAttended = true;
            body.isLate = false;
          } else if (value === 'leftEarly&Late') {
            body.hasLeftEarly = true;
            body.isLate = true;
            body.hasAttended = true;
          } else if (value === 'absent') {
            body.hasAttended = false;
            body.engagement = 'absent';
            body.understanding = 'absent';
          }
        }

        await updateTeacherAttendance({ id: props.id, registerStudent: body });
      }}
    />
  );
};
