import styled from 'styled-components';
import { ClassCheckersRegisterTable } from './ClassCheckerRegisterTable';

const ClassCheckersRegisterStyled = styled.div`
  .table-header {
    background: #f1f1f5;
    border-radius: 10px;
    padding: 15px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #92929d;
  }

  .student-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #44444f;
  }

  .student-id {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #92929d;
  }
`;

interface ClassCheckersRegisterProps {
  lessonData?: any;
}

export const ClassCheckersRegister = (props: ClassCheckersRegisterProps) => {
  return (
    <ClassCheckersRegisterTable
      data={mapDataToView(props.lessonData?.data?.attributes?.register_students?.data, props.lessonData?.data?.id)}
    />
  );
};

const mapDataToView = (rawData: any, registerId: number) => {
  const formatted: any[] = [];
  rawData?.map((rel: any) => {
    const student = {
      id: rel?.attributes?.student?.data?.id,
      avatarImageName: rel?.attributes?.student?.data?.attributes?.avatarImageName,
      name: rel?.attributes?.student?.data?.attributes?.name,
      lastName: rel?.attributes?.student?.data?.attributes?.lastName,
      studentId: rel?.attributes?.student?.data?.attributes?.studentId,
      freeTrial: rel?.attributes?.freeTrial,
      isSen: rel?.attributes?.student?.data?.attributes?.isSen,
      cancellationPendingDate: rel?.attributes?.student_class?.data?.attributes?.cancellationPendingDate || null
    };

    const teacherAttendance = {
      status: rel.attributes,
      relId: rel.id,
      registerId: registerId
    };

    const checkerAttendance = {
      value: rel.attributes.checkerAttendance,
      relId: rel.id,
      registerId: registerId
    };

    const contactParent = {
      studentId: rel.attributes.student.data.id,
      primaryParentId: rel.attributes.student.data.attributes.primary_parent.data.id,
      relId: rel.id,
      callLogs: rel.attributes.callLogs,
      registerId: registerId,
      student: rel.attributes.student.data.attributes
    };

    const status =
      rel.attributes.callLogs?.length > 0 ? rel.attributes.callLogs[rel.attributes.callLogs?.length - 1] : undefined;

    const leavePeriod = {
      start: rel.attributes.student.data.attributes.startLeavePeriod,
      end: rel.attributes.student.data.attributes.endLeavePeriod,
      studentId: rel.attributes.student.data.id
    };

    formatted.push({
      student: student,
      teacherAttendance: teacherAttendance,
      checkerAttendance: checkerAttendance,
      contactParent: contactParent,
      status: status,
      leavePeriod: leavePeriod
    });
  });
  return formatted;
};
