import { Select } from 'app/components/v1/Select';
import { useUpdateRegisterStudentMutation } from 'app/services/register-student';

interface CheckerAttendanceProps {
  value: string;
  relId: string;
  registerId: number;
  className?: string;
}

export const CheckerAttendanceInput = (props: CheckerAttendanceProps) => {
  const [updateCheckerAttendance, { isLoading }] = useUpdateRegisterStudentMutation();

  const checkerAttendanceOptions = [
    { label: <span style={{ color: '#3DD598' }}>Present</span>, value: 'present' },
    { label: <span style={{ color: '#FFC542' }}>Coming</span>, value: 'coming' },
    { label: <span style={{ color: '#FC5A5A' }}>Not Coming</span>, value: 'notComing' },
    { label: <span style={{ color: '#FC5A5A' }}>No Answer</span>, value: 'noAnswer' }
  ];

  return (
    <Select
      className={`checker-attendance ${props.className}`}
      placeholder="Checker Attendance"
      dropdownStyle={{ color: 'green' }}
      allowClear
      value={props.value}
      containerclass="d-flex flex-row justify-content-center mx-2 mt-2"
      onChange={async (selectedValue) => {
        await updateCheckerAttendance({
          id: props.relId,
          registerStudent: {
            checkerAttendance: selectedValue ?? null,
            registerId: props.registerId
          }
        });
      }}
      disabled={isLoading}
      options={checkerAttendanceOptions}
      style={{ width: '170px', minWidth: '170px' }}
    />
  );
};
