import qs from 'qs';
import api from '.';
import { GetGroupsResponse, RequestQuery } from './types';

export const groupApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    findGroups: builder.query<GetGroupsResponse, RequestQuery>({
      query: (payload) => {
        const query =
          qs.stringify(
            {
              ...payload,
              pagination: {
                limit: -1
              },
              sort: ['order:asc', 'name:asc', ...(payload.sort ?? [])]
            },
            { encodeValuesOnly: true }
          ) || '';
        return {
          url: `/api/groups${query ? `?${query}` : ''}`,
          method: 'GET'
        };
      }
    })
  })
});
