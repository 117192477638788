import Avatar from 'app/components/Icons/Avatar';
import Dot from 'app/components/Typography/Dot';
import Typography from 'app/components/Typography/Typography';
import ClassID from 'app/features/admin-dashboard/sales/components/ClassID';
import StudentsSubscribedToClassCounts from 'app/features/admin-dashboard/sales/edit-student-class/StudentsSubscribedToClassCounts';
import { adminApi } from 'app/services/admin';
import { classApi } from 'app/services/class';
import { convertTimeFromUTC } from 'app/utils/utils';
import _ from 'lodash';
import qs from 'qs';
import styled from 'styled-components';
import Container from '../Container/Container';
import { IconComponents } from '../Icons';
import StatusComponent from '../StatusTitle/StatusComponent';
import Dash from '../Typography/Dash';
import { Button } from '../v1/Button';
import { Toggler } from '../v1/toggler/Toggler';

const Styled = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  width: 340px;
  min-height: 150px;

  .student-name {
    font-family: var(--st-font-family-secondary);
    font-size: 14px;
    font-weight: 600;
  }

  .section-sub-title {
    font-family: var(--st-font-family-secondary);
    font-size: 14px;
    font-weight: 600;
    color: #44444f;
  }

  .classroom-subject-name {
    font-size: 22px;
  }

  .classroom-year-name {
    font-size: 22px;
  }

  .status {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    padding: 0;
    margin-top: 4px;
  }

  .class-id {
    padding: 2px 10px;
    border-radius: 10px 0px 0px 0px;
    border: 1px solid #696974;
  }

  .classroom-date {
    display: flex;
    align-items: center;
    color: #44444f;

    .dot {
      font-family: var(--st-font-family-secondary);
      font-size: 16px;
      font-weight: 400;
      margin: 0 5px;
    }
  }
  .classroom-date,
  .classroom-from-time,
  .classroom-to-time {
    font-family: var(--st-font-family-secondary);
    font-size: 16px;
    font-weight: 400;
    color: #44444f;
  }

  .classroom-day-of-week {
    text-transform: capitalize;
  }

  .class-students-icon {
    width: 35px;
    height: 35px;
    color: gray;
  }

  .classroom-zoom-link-icon {
    color: #2196f3;
  }

  .classroom-zoom-link-container {
    line-height: 14px;
  }

  .classroom-zoom-link {
    font-family: var(--st-font-family-secondary);
    font-size: 10px;
    font-weight: 400;
  }

  .students-count {
    font-family: var(--st-font-family-secondary);
    font-size: 14px;
    font-weight: 600;
    text-align: start;
  }

  .student-status-count {
    font-family: var(--st-font-family-secondary);
    line-height: 14px;
    font-weight: 400;
    text-align: start;

    > span {
      font-size: 10px;
    }
  }

  .zoom-link {
    margin-top: 13px;
  }

  .students-count-toggler {
    margin-left: -12px;
    margin-top: 13px;
  }

  .classroom-contributors-section {
    margin-left: 42px;
    max-height: 200px;
    overflow-y: auto;
  }

  .classroom-info-section {
    .dot {
      font-family: var(--st-font-family-primary);
      font-size: 22px;
      font-weight: 700;
      margin: 0 10px;
    }
  }
`;

export const EventPopup = (props: { event?: any }) => {
  const { data: classData, isLoading: isLoadingClassData } = classApi.useGetClasseroomsQuery({
    requestQuery: qs.stringify({
      filters: {
        classId: props.event?.resource?.classId
      },
      populate: ['subject', 'year'],
      population: {
        limit: 1
      }
    })
  });

  const { data: classSummaryData, isLoading: isLoadingClassSummary } = adminApi.useGetClassesForSalesQuery(
    {
      studentId: props.event?.resource?.studentId,
      classType: classData?.data?.at(0)?.attributes?.type!,
      subjectId: classData?.data?.at(0)?.attributes?.subject?.data?.id,
      yearId: classData?.data?.at(0)?.attributes?.year?.data?.id,
      classId: classData?.data?.at(0)?.id?.toString()!,
      statuses: props.event?.onlyStatuses,
      options: {
        includePreviousLessons: true
      }
    },
    {
      skip: !props.event?.resource?.studentId || !classData?.data?.at(0)?.id
    }
  );

  return (
    <Styled isLoading={isLoadingClassSummary || isLoadingClassData}>
      <div className="d-flex flex-column gap-2">
        <div className="classroom-info-section d-flex flex-row align-items-center">
          <div className="classroom-year-name">{props.event?.resource?.year!}</div> <Dot className="dot" />{' '}
          <div className="classroom-subject-name">{props.event?.resource?.subject!}</div> <Dot className="dot" />{' '}
          <div className="classroom-subject-name">{_.capitalize(props.event?.resource?.classType)}</div>{' '}
          <StatusComponent status={classSummaryData?.classData?.at(0)?.status!} className="status" as="dot" />
        </div>
        <div className="classroom-date gap-1">
          {classData?.data?.at(0)?.attributes?.dayOfWeek ? (
            <>
              <div className="classroom-day-of-week">{classData?.data?.at(0)?.attributes?.dayOfWeek}</div>{' '}
              <Dot className="dot" />{' '}
            </>
          ) : (
            <></>
          )}
          <span>
            {' '}
            {convertTimeFromUTC(`${props.event?.resource?.date} ${props.event?.resource?.startTime}`).format('hh:mm A')}
          </span>
          <Dash />
          <span>
            {' '}
            {convertTimeFromUTC(`${props.event?.resource?.date} ${props.event?.resource?.endTime}`).format('hh:mm A')}
          </span>
        </div>
        <ClassID
          readonly
          onClick={(evt: Event) => {
            evt.preventDefault();
            evt.stopPropagation();
            window.open(`/admin/classrooms/${classData?.data?.at(0)?.id}`, '_blank');
          }}
          data={{
            classId: props.event?.resource?.classId,
            id: classData?.data?.at(0)?.id?.toString()!,
            status: props.event?.resource?.status
          }}
        />
      </div>
      {props.event?.resource?.studentName && (
        <Button
          buttonType="text"
          style={{ background: 'transparent', justifyContent: 'flex-start' }}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            window.open(`/admin/students/${props.event?.resource?.studentId}`, '_blank');
          }}
          className="d-flex flex-row gap-2 align-items-center mt-3"
        >
          <Avatar name={props.event?.resource?.studentAvatarImageName} width="20px" height="20px" />
          <span className="student-name">{props.event?.resource?.studentName}</span>
        </Button>
      )}
      {props.event?.resource?.entityName && (
        <Button
          buttonType="text"
          style={{ background: 'transparent', justifyContent: 'flex-start' }}
          onClick={(evt) => {
            if (props.event?.resource?.forEntityType !== 'teacher') return;
            evt.preventDefault();
            evt.stopPropagation();
            window.open(`/admin/teachers/${props.event?.resource?.teacherId}`, '_blank');
          }}
          className="d-flex flex-row gap-2 align-items-center mt-3"
        >
          <span className="student-name">{props.event?.resource?.entityName}</span>
        </Button>
      )}
      {classSummaryData?.classData?.at(0)?.students.length ? (
        <Toggler
          className="students-count-toggler"
          title={
            <div className="d-flex flex-row gap-2">
              <IconComponents.ClassStudentsIconComponent className="class-students-icon" />
              <div className="d-flex flex-column">
                <div className="students-count">{classSummaryData?.classData?.at(0)?.students.length} Students</div>
                <StudentsSubscribedToClassCounts
                  className="student-status-count"
                  statusCounts={classSummaryData?.classData?.at(0)?.statusCounts ?? []}
                />
              </div>
            </div>
          }
        >
          <div className="classroom-contributors-section d-flex flex-column gap-1 mt-2">
            {classSummaryData?.classData?.at(0)?.students?.map((e) => {
              return (
                <Button
                  buttonType="text"
                  style={{ background: 'transparent', justifyContent: 'flex-start' }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    window.open(`/admin/students/${e.studentDbId}`, '_blank');
                  }}
                  className="d-flex flex-row gap-2 align-items-center"
                  key={e.id}
                >
                  <Avatar name={e.avatarImageName} width="20px" height="20px" />
                  <span className="student-name">{e.name}</span>
                </Button>
              );
            })}
          </div>
        </Toggler>
      ) : (
        <></>
      )}

      {classData?.data?.at(0)?.attributes?.zoomLink && (
        <div className="d-flex flex-row gap-2 align-items-center mt-3">
          <IconComponents.ClassroomZoomLinkIconComponent className="classroom-zoom-link-icon" />
          <div className="zoom-link">
            <div className="section-sub-title">Zoom link</div>
            <Typography
              className="classroom-zoom-link"
              containerClassName="classroom-zoom-link-container"
              type="link"
              link={classData?.data?.at(0)?.attributes?.zoomLink!}
            >
              {classData?.data?.at(0)?.attributes?.zoomLink}
            </Typography>
          </div>
        </div>
      )}
    </Styled>
  );
};
